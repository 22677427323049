<template>
    <div class="StatementOne" id="StatementOne">
      <div class="tabs" style="height:100%;">
        <!-- <div class="tabsTop">
          <img src="../../public/image/fenlei.png" alt="">
          <div class="inputBox">
            <img src="../../public/image/sousuo.png" alt="">
            <input type="text" v-model="search" placeholder="搜索框">
          </div>          
          <div class="left">
            <span>11-11</span>
            <img src="../../public/image/xiangxiajiantou.png" alt="">
          </div>
        </div> -->
        <v-card style="height:100%;">
          <v-tabs v-model="tab" light color="#4D78AE" height="40px">
            <!-- <v-tab @click="tabsClick(0)">概览</v-tab> -->
            <v-tab v-for="(item,index) in items" :key="index" @click="tabsClick(item)">{{ item.tag_name }}</v-tab>
          </v-tabs>
          <div class="wrapper" ref="wrapper" style="height:100%;overflow: hidden;">
            <iframe :src="address" id="mobsf" scrolling="yes" frameborder="0" style="width:100%;height:100%;"></iframe>
          </div>
        </v-card>
      </div>
    </div>
</template>

<script>
// import BSView from "@/components/BSView.vue";
// import html2canvas from 'html2canvas';\
import md5 from 'js-md5'
export default {
  name: 'StatementOne',
  components: {
    // BSView
  },
  data () {
    return {
      tab:0,
      search:'',
      items:[
        {
          id:1,
          name:'概况'
        },
        {
          id:2,
          name:'客户'
        },
        {
          id:3,
          name:'渠道'
        },
        {
          id:4,
          name:'供货'
        },
        {
          id:5,
          name:'销售'
        },
        {
          id:6,
          name:'库存'
        },
        
      ],
      address:''
    }
  },
  watch: {
    "$route": "fetchData"
  },
  methods: {
    tabsClick(val) {
      // console.log(val.url)
      // console.log(this.tab)
      this.address = val.url
    },
    fetchData() {
      if(this.$route.path=='/StatementOne') {
        this.getArticleDetails(this.$route.query.id)
        if(!$core.getLocal('StatementOneId')) {
          let now = new Date();
          let nowTime = now.getTime();//获取当前时间时间戳
          $core.setLocal('StatementOneId',this.CalcuMD5(String(nowTime)))
          $core.setLocal('StatementOneMsgId',this.$route.query.id)
          this.getUserActive('打开报表','打开')
        }  
      }
      if(this.$route.path=='/') {
        this.getUserActive('打开报表','关闭')
        $core.clearLocal('StatementOneId')
        $core.clearLocal('StatementOneMsgId')
      }
        
    },
    getArticleDetails(id) {
      $core.request('front/analyse/get', res => {
        // console.log(res)
        if(res.status == 200) {
          this.items = JSON.parse(res.data.url_json)
          this.address = JSON.parse(res.data.url_json)[0].url
          this.tab = 0
        }
      },{
        'id':id
      });
    },
    CalcuMD5(pwd) {
      let num = String(Math.floor(Math.random() * (10000 - 1)) + 1)
      pwd = md5(num+pwd);
      return pwd;
    },
    getUserActive(name,event) {
      $core.request('growdata/add', res => {
        // console.log(res)
      },{
        'id':$core.getLocal('StatementOneMsgId'),//消息id
        "page_id":$core.getLocal('StatementOneId'),//页面id
        'name':encodeURI(name),//事件类型名称
        'event':encodeURI(event),//事件
        'type':2,//消息还是列表
      });
    },
  },
  mounted() {
    // console.log('111')
    if(!$core.getLocal('StatementOneId')) {
      let now = new Date();
      let nowTime = now.getTime();//获取当前时间时间戳
      $core.setLocal('StatementOneId',this.CalcuMD5(String(nowTime)))
      $core.setLocal('StatementOneMsgId',this.$route.query.id)
      this.getUserActive('打开报表','打开')
    }  
  },
  beforeDestroy() {
    this.getUserActive('打开报表','关闭')
  },
  destroyed() {
    $core.clearLocal('StatementOneId')
    $core.clearLocal('StatementOneMsgId')
  },
  created() {
    document.title = '报表1'
    this.getArticleDetails(this.$route.query.id)
    // console.log(1)
  }
};
</script>

<style lang="less" scoped>
/* 设置滚动条的样式 */
::-webkit-scrollbar{
    width: 3px;
}
/*滚动槽*/
::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
}
  .StatementOne{
    /deep/ .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
      display: none;
    }
    /deep/ .v-tabs-bar {
      border-radius: 0px;
    }
    .tabs {
      .v-tab {
        min-width: 50px;
        max-width: 70px;
        padding: 0 10px;
      }
      /deep/ .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none;
      }
      /deep/ .v-tab:before {
        background-color: white;
      }
      /deep/ .v-ripple__container {
        display: none;
      }
      /deep/ .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
      .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
      .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
      .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
        color: #d6d6d6;
        font-size: 16px;
      }
      /deep/ .v-tabs {
        border-bottom: 1px solid #f2f2f2;
      }
      /deep/.v-tab--active {
        // color: inherit;
        font-size: 16px;
        font-weight: 800;
      }

      /deep/ .v-card__title {
          font-size: 18px;
          color: rgba(255, 255, 255, 0.7);
      }
      /deep/ .v-card__text {
        color: white;
      }

      // 内容
      .content {
        background: white;
        padding: 15px;
        padding-top: 0;
      }

      .tabsTop {
        height: 40px;
        background: #4D78AE;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        img {
          width: 22px;
          height: 22px;
          margin-left: 10px;
        }
        .left {
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
        }
        .inputBox {
          background: white;
          display: flex;
          padding: 3px 0;
          width: 60%;
          img {
            width: 20px;
            height: 20px;
            margin-right: 0px;
          }
          input {
            padding: 0 10px;
            outline:0px;
            font-size: 14px;
          }
        }
      }
    }
  }

</style>